<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>字典管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="toolbar flex flex-wrap">
          <el-button type="primary" icon="plus" @click="addDict()">
            新增
          </el-button>
          <!-- <el-button type="primary" plain icon="el-icon-s-fold" size="small">
            展开
          </el-button>
          <el-button type="primary" plain icon="el-icon-s-unfold" size="small">
            折叠
          </el-button> -->

          <el-dropdown style="float: right">
            <el-button type="primary" icon="more"> 更多 </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="edit(rootRow)">编辑</el-dropdown-item>
                <el-dropdown-item @click="del(rootRow)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <el-table class="table" :data="treeData" row-key="id" border highlight-current-row
          @current-change="handleCurrentChange" :default-expand-all="defaultExpand"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column prop="code" label="编号" width="130"></el-table-column>
          <el-table-column prop="name" label="名称" width="120">
          </el-table-column>
          <el-table-column prop="value" label="值"> </el-table-column>
        </el-table>
      </el-col>

      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
        <el-form :model="forms" ref="forms" label-width="70px" class="search-form">
          <el-row>
            <el-form-item label="关键词" prop="keyword">
              <el-col>
                <el-input v-model="forms.keyword" prefix-icon="Search"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="" label-width="20px">
              <el-button type="primary" icon="search" @click="fetchDictLoad">
                搜索
              </el-button>
            </el-form-item>
          </el-row>
        </el-form>
        <div class="toolbar">
          <el-button type="primary" icon="plus" @click="add(rootRow)">新增</el-button>
          <el-button type="danger" icon="delete" @click="del(currRow)">删除</el-button>
        </div>
        <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
          <template v-slot:enabled="scope">
            <el-switch v-model="scope.row.enabled" active-color="#13ce66"
              inactive-color="#ff4949" active-text="" inactive-text="" :disabled="true">
            </el-switch>
          </template>
          <template v-slot:toolbar="scope">
            <el-button @click="edit(scope.row)" type="primary" link size="small">[编辑]</el-button>
          </template>
        </static-table>
      </el-col>
    </el-row>
  </el-card>

  <el-dialog title="字典维护" width="600px" destroy-on-close v-model="formDialogVisible" @close="close">
    <el-form :model="forms" :rules="formRules" ref="forms" :label-width="formLabelWidth">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="forms.code" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="名称" prop="name">
            <el-input v-model="forms.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="字典内容">
            <el-input v-model="forms.value" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="启用">
            <el-switch v-model="forms.enabled" active-color="#13ce66" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" style="margin-left: 10px"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="属性">
            <el-input v-model="forms.attribute" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="排序号">
            <el-input-number v-model="forms.sortCode" :min="1" :max="9999999" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="备注">
            <el-input v-model="forms.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="formDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit"> 确 定 </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { fetchDictTree, submitDict, delDict, fetchDictPager } from "@/api/basic";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "Dictionary",
  components: { StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "code", label: "编号", sortable: true, searchable: true, width: "85", showOverflowTooltip: true },
          { prop: "name", label: "名称", sortable: true, searchable: true, width: "120", showOverflowTooltip: true },
          { prop: "value", label: "值", sortable: true, searchable: true, width: "140", showOverflowTooltip: true, },
          { prop: "attribute", label: "属性", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "remark", label: "备注", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { type: "template", prop: "enabled", label: "状态", sortable: true, width: "90", },
          { prop: "sortCode", label: "排序号", sortable: true, width: "80" },
          { prop: "createDate", label: "创建时间", width: "140", sortable: true },
          { prop: "createUserName", label: "创建人", width: "80", },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "sortCode", //默认的排序字段
        sord: "asc",
      },
      forms: {
        keyword: "",
      },
      rootRow: "",
      currRow: "",
      defaultExpand: true, //默认展开所有节点
      // treeData: [
      //   { id: 1, name: "基础项1", code: "code1", attr: "", },
      //   { id: 2, name: "基础项2", code: "code2", attr: "", },
      //   {
      //     id: 3, name: "基础项3", code: "code3", attr: "",
      //     children: [
      //       { id: 31, name: "基础项31", code: "code31", attr: "", },
      //       { id: 32, name: "基础项32", code: "code32", attr: "", },
      //     ],
      //   },
      //   { id: 4, name: "基础项4", code: "code4", attr: "", },
      // ],
      treeData: [],

      // tableData: [
      //   { code: "1001", name: "name1001", attr: "name1001", },
      //   { code: "1002", name: "name1002", attr: "name1002", },
      //   { code: "1003", name: "name1003", attr: "name1003", },
      //   { code: "1004", name: "name1004", attr: "name1004", },
      //   { code: "1005", name: "name1005", attr: "name1005", },
      // ],

      formDialogVisible: false,
      forms: {},
      formLabelWidth: "90px",
      formRules: {
        name: [
          { required: true, message: "请输入字典项名称", trigger: "blur" },
          { min: 0, max: 50, message: "长度在0-50之间", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入字典项编号", trigger: "blur" },
          { min: 0, max: 50, message: "长度在0-50之间", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.fetchDictRoot();
  },
  methods: {
    edit(row) {
      if (!row || (row.id || "").length == 0) {
        this.$message.error("请选择要编辑的数据");
        return;
      }
      this.formDialogVisible = true;
      this.forms = row;
      if(row.sort) this.forms.sortCode = row.sort;
      if(row.attr) this.forms.attribute = row.attr;
    },
    addDict() {
      let idValue = this.rootRow ? (this.rootRow.id || "") : "";
      this.forms = {
        category: "dict",
        parentID: idValue,
        sortCode: 1,
      };
      this.formDialogVisible = true;
    },
    add(row) {
      if (!row || (row.id || "").length == 0) {
        this.$message.error("请选择对应左侧的节点");
        return;
      }
      this.formDialogVisible = true;
      this.forms = {
        category: row.code,
        code: row.value,
        sortCode: row.sortCode,
      };
    },
    del(row) {
      if (!row || (row.id || "").length == 0) {
        this.$message.error("请选择要删除的数据");
        return;
      }
      let that = this;
      this.$confirm("此操作将删除选择的数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delDict(row.id).then((res) => {
            if (res.code === 200) {
              that.$message.success("删除成功");
              that.fetchDictLoad();
            }
          });
        })
        .catch(() => {
          that.$message.error("删除失败");
        });
    },
    close() {
      this.$refs.forms.resetFields();
    },
    handleCurrentChange(item) {
      this.rootRow = item;
      if (item) this.fetchDictList(item.code, item.value);
    },

    fetchDictRoot() {
      var that = this;
      fetchDictTree().then((res) => {
        that.treeData = res.data;

        if (that.rootRow)
          that.fetchDictList(that.rootRow.code, that.rootRow.value);
      });
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        submitDict(this.forms).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });
            that.formDialogVisible = false;

            //that.fetchDictRoot();
            that.fetchDictLoad();
          }
        });
      });
    },
    fetchDictLoad() {
      if (this.rootRow)
        this.fetchDictList(this.rootRow.code, this.rootRow.value);
    },
    fetchDictList(category, code) {
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      search.filters = [];
      search.filters.push({
        name: "category",
        value: category,
        operate: 0,
      });
      search.filters.push({
        name: "code",
        value: code,
        operate: 0,
      });

      var that = this;
      fetchDictPager(search).then((res) => {
        that.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchDictLoad();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchDictLoad();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchDictLoad();
    },
  },
};
</script>